.dou-page-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    .img-no-file-dou {
        width: 250px !important;
    }

    .carrega-mais {
        margin-bottom: 16px !important;
    }

    .dou-page-itens {
        width: 100%;
        display: flex;
        flex-direction: column;

        .dou-page {
            margin: 16px;
            border-bottom: 0.5px solid #dbe0ec;
            color: #666;

            &:last-child {
                border-bottom: 0px;
            }

            .dou-cliente {
                display: block;
                flex-direction: row;
                align-items: center;
                border-bottom: 1px solid #eaf0f7;
                padding: 6px 0;
                margin-bottom: 18px;

                i {
                    color: #333;
                    margin-right: 6px;
                }
            }

            .dou-page-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                > div {
                    color: #333;
                }

                .dou-page-data {
                    i {
                        color: #666;
                        margin-right: 6px;
                    }
                }
            }

            .dou-page-descricao {
                font-size: 11px;
                opacity: 0.8;
                margin-top: 2px;
                color: #666;
                width: 80%;
            }

            .dou-page-link {
                margin-top: 16px;
                margin-bottom: 10px;
                font-size: 12px;

                a {
                    background-color: #20af62;
                    color: #fff;
                    padding: 6px;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                i {
                    color: #fff;
                    margin-right: 4px;
                }
            }

            .buscoupor {
                font-size: 10px !important;
                margin-top: -13px !important;
                margin-bottom: 16px !important;
                opacity: 0.5 !important;
            }
        }
    }

    .dou-page-info {
        border-left: 1px solid #dbe0ec;
        padding-left: 16px;
        padding-top: 16px;
        padding-right: 16px;
        font-size: 11px;
        color: #666;
        display: flex;
        flex-direction: column;
        width: 34%;

        .dou-page-info-titulo {
            font-size: 20px;
            margin-bottom: 8px;
            color: #333;

            i {
                color: #666;
                margin-top: 100px;
                margin-right: 6px;
            }
        }
    }
}
