.validaRetorno {
    text-transform: uppercase;
    color: red;
    font-size: 10px;
    text-align: center;
    width: 100%;
    padding: 10px;
}

.left-sidenav {

    .main-icon-menu {
        background-color: #20af62 !important;        
    }
} 

.bg-soft-success {
    background-color: rgba(32, 175, 98, 0.15) !important;
    color: #24af62 !important;
}

label {
    color: #228d54 !important;
}

.modal-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.nofiles {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 250px;
        padding: 20px;
        opacity: 0.5;
    }
}

.nofiles-alt {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 250px;
        padding: 20px;
        opacity: 0.5;
    }

    span {
        opacity: 0.6;
        font-size: 10px;
        text-transform: uppercase;
    }
}

.nofilestable {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 150px;
        padding: 10px;
        opacity: 0.5;
    }
}

.notification-wrapper {
    z-index: 99999999999 !important;
}

.btn-soft-success:hover {
    background-color: #24af62;
}

.btn-soft-success {
    background-color: rgba(30, 202, 184, 0.1);
    color: #24af62;
    }
  
.btn-soft-success:focus {
-webkit-box-shadow: 0 0 0 0.1rem rgba(30, 202, 184, 0.2);
        box-shadow: 0 0 0 0.1rem rgba(30, 202, 184, 0.2);
background-color: rgba(36, 175, 98, 0.8);
color: #ffffff;
}

table tbody {
    font-size: 11px !important;
}