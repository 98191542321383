.card-header {
    margin: -18px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
        color: #50649c;
        font-size: 16px;
        margin-right: 6px;
    }
}

.card-footer {
    border-top: 1px solid #eaf0f7;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: -16px;    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
        color: #50649c;
        margin-right: 10px;
    }

    &:hover {
        opacity: 0.8;
    }
}

.dou-dash {
    margin: -16px;

    .dou-dash-cliente {
        display: block;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #eaf0f7;
        padding: 6px 0;
        margin-bottom: 18px;

        i {
            color: #333;
            margin-right: 6px;
        }
    }

    .dou-dash-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > div {
            color: #333;
        }

        .dou-dash-data {
            i {
                color: #666;
                margin-right: 6px;
            }
        }
    }

    .dou-dash-descricao {
        font-size: 11px;
        opacity: 0.8;
        margin-top: 2px;
        color: #666;
    }

    .dou-dash-link {
        margin-top: 16px;
        margin-bottom: 10px;
        font-size: 12px;

        a {
            background-color: #20af62;
            color: #fff;
            padding: 6px 16px;

            &:hover {
                opacity: 0.8;
            }
        }

        i {
            color: #fff;
            margin-right: 4px;
        }
    }
}
